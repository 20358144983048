<template>
  <div class="flex justify-center items-center p-5">
    <div class="text-6xl text-prasset-gray-200">404</div>
    <div class="text-xl text-prasset-gray-800 mb-4">Pagina niet gevonden</div>
    <RouterLink :to="{ name: 'login' }" class="button button--opague"><i class="ri-arrow-left-s-line mr-1" />Ga terug naar start</RouterLink>
  </div>
</template>

<script>
export default {

};
</script>
